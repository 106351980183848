.c-footer-button {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: $white-color;
  border: 1px solid $white-color;
  border-radius: 34px;
  padding: 8px 20px;
  position: relative;

  svg {
    fill: $white-color;
    display: block;
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    background-color: $white-color;
    color: $dark-blue-color;
    text-decoration: none;

    svg {
      fill: $dark-blue-color;
    }
  }
}
