.c-body {
  font-size: 13px;
  line-height: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
    line-height: 24px;
  }

  @include media-breakpoint-up(xl) {
    line-height: 26px;
  }

  h2:not([class]) {
    font-size: 24px;
    line-height: 28px;
    color: $brand-secondary;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 34px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 38px;
      line-height: 38px;
      margin-bottom: 30px;
    }
  }

  h3:not([class]) {
    font-size: 18px;
    line-height: 22px;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;
      position: relative;
      margin: 0;
      padding-left: 20px;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '●';
        color: $brand-secondary;
      }
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
