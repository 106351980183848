.c-main {
  margin-bottom: 40px;

  img {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  }
}

.c-main__top {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url('../images/heyndrickx-watermark.jpg') no-repeat center center;

  .row {
    &:nth-child(odd) {
      @include media-breakpoint-down(md) {
        .mb-lg-0{
          
          margin-top: 1rem;
        }
      }
      @include media-breakpoint-up(lg) {
        
        .c-content-box {
          padding-right: 100px;
        }
      }
    }

    &:nth-child(even) {
      @include media-breakpoint-up(lg) {
        .mb-lg-0{
          order: 2;
        }
        .c-content-box {
          padding-left: 100px;
        }
      }
    }
  }
}

.c-page__top{
  padding-top: 50px;
  padding-bottom: 50px;
  background: url('../images/heyndrickx-watermark.jpg') no-repeat center center;

  .row {
    @include media-breakpoint-up(lg) {
      .c-content-box {
          padding-right: 100px;
      }

      &:nth-child(odd) {
        .mb-lg-0{
            order: 2;
            .c-content-box {
              padding-right: 0px;
            }
        }
        .image-group{
              padding-right: 100px;
            }
      
      }

    }
  }
}