.c-footer-nav__list {
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: center;
  }

  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
  }

  .menu-item {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        display: flex;
        align-items: center;

        &::after {
          content: '—';
          display: inline-block;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
