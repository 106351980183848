// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$base-font-family: 'Open Sans', Arial, sans-serif;

// Colors
$white-color: #fff;
$black-color: #000;
$brand-primary: #1566b0;
$brand-secondary: #2783C5;

$body-bg: $white-color;
$body-color: #263344;

$light-grey-color: #CCC;

$dark-blue-color: #112540;

// Weight
$headings-font-weight: 700;

// Buttons
$border-width: 2px;
$btn-border-radius: 20px;
$btn-font-weight: bold;
$btn-primary-bg: $brand-secondary;
$btn-primary-border: $brand-secondary;

// Forms
$input-bg: #fafafa;
$input-border-color: rgba($dark-blue-color, 0.5);
