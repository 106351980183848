.c-page-logo {
  display: inline-block;

  @include media-breakpoint-between(md, lg) {
    margin-bottom: 30px;
  }
}

.c-page-logo__image {
  @include media-breakpoint-up(md) {
    width: 322px;
    height: 78px;
  }
}
