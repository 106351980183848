.c-primary-nav {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-between(md, xl) {
    justify-content: center;
  }

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
    align-items: center;
  }

  .nav {
    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
    }

    li {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        font-size: 18px;
        color: #fff;
        font-family: $base-font-family;
        padding: 10px;
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        @include media-breakpoint-up(md) {
          font-size: 13px;
          padding-top: 8px;
          padding-bottom: 23px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }

        &:hover,
        &:focus {
          color: $brand-secondary;
          text-decoration: none;
        }
      }

      &:not(.menu-contact) {
        &.active {
          a {
            @include media-breakpoint-down(sm) {
              color: $brand-primary;
            }

            @include media-breakpoint-up(md) {
              position: relative;

              &:after {
                content: '';
                position: absolute;
                display: block;
                width: 50%;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                height: 2px;
                background-color: $brand-secondary;
              }
            }
          }
        }
      }

      &.menu-contact {
        margin-top: 10px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-left: 10px;
        }

        a {
          border: 2px solid $white-color;
          border-radius: 40px;
          padding-right: 25px;
          padding-bottom: 7px;
          padding-left: 25px;

          &:hover,
          &:focus {
            background-color: $white-color;
          }
        }

        &.active {
          a {
            background-color: $white-color;
            color: $brand-primary;
          }
        }
      }
    }
  }
}

.c-primary-nav__menu {
  display: none;

  @include media-breakpoint-down(sm) {
    @at-root .has-overlay & {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: $dark-blue-color;
      padding: 100px 30px 30px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.c-primary-nav__menu__logo {
  display: none;

  @include media-breakpoint-down(sm) {
    @at-root .has-overlay & {
      display: block;
      position: absolute;
      top: 20px;
      left: 15px;
    }
  }
}

.c-primary-nav__show,
.c-primary-nav__hide {
  display: block;
  width: 38px;
  height: 31px;
  appearance: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background: $white-color;
  border: 0;
  cursor: pointer;
  position: relative;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

.c-primary-nav__hide {
  position: absolute;
  right: 15px;
  top: 30px;
}

.c-primary-nav__show__icon,
.c-primary-nav__hide__icon {
  width: 24px;
  height: 19px;
  position: absolute;
  left: 7px;
  top: 6px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $brand-primary;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
}

.c-primary-nav__show__icon {
  span {
    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }
}

.c-primary-nav__hide__icon {
  span {
    &:nth-child(1) {
      top: 12px;
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      top: 8px;
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      top: 8px;
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 12px;
      width: 0;
      left: 50%;
    }
  }
}
