.c-highlight {
  background-color: $dark-blue-color;
  color: $white-color;
  padding: 25px;
  font-size: 12px;

  a {
    text-align: center;
    display: block;
    border: 2px solid $brand-secondary;
    border-radius: 20px;
    color: $white-color;
    line-height: 20px;
    padding: 10px 20px;
    transition: background 0.2s ease;
    background-color: $brand-secondary;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: transparent;

      strong {
        color: $brand-secondary;
        
      }
    }

    strong {
      color: $white-color;
      text-transform: uppercase;
    }

    span {
      font-weight: 700;
    }
  }
}

.c-highlight__title {
  font-size: 32px;
  line-height: 42px;
  color: inherit;
}
