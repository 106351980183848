.c-forms {
  background-color: $white-color;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 25px 50px;
  font-size: 14px;

  @include media-breakpoint-up(lg) {
    padding: 50px 100px;
  }

  .control-label {
    font-weight: bold;
  }

  .form-control {
    border-width: 1px;
  }

  .field_required {
    font-weight: 400;
    color: $dark-blue-color !important;
  }
}

.c-forms__title {
  font-size: 18px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.first_row {
  .c-forms__title {
    margin-top: 0;
  }
}
