.upper-footer {
  font-size: 14px;
  line-height: 28px;
 	background-color: $dark-blue-color;
 	color: $white-color;
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  a:not([class]) {
    color: $brand-secondary;
  }
}

.footer {
  font-size: 12px;
  background-color: shade($dark-blue-color, 30%);
  color: #a3a3a3;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  a {
    color: $light-grey-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $white-color;
    }
  }
}

.c-footer-image {
  @include media-breakpoint-down(md) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
