.c-jumbotron {
  background: no-repeat center center;
  background-size: cover;
}

.c-jumbotron__col {
  height: 550px;

  @include media-breakpoint-up(lg) {
    height: 650px;
  }
}

.c-jumbotron__box {
  background-color: $white-color;
  padding: 30px;
  position: absolute;
  left: 15px;
  bottom: 0;

  @include media-breakpoint-down(lg) {
    width: calc(100% - 30px);
  }

  @include media-breakpoint-up(lg) {
    &::before {
      content: '';
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $white-color;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-50vw);
      z-index: 10;
    }
  }
}

.c-jumbotron__content {
  position: relative;
  z-index: 20;
}
