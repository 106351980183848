// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: 15px auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: 15px;
  height: auto;
}

