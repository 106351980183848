.c-hero {
  background: no-repeat center center;
  background-size: cover;
  position: relative;

  .container {
    min-height: 380px;
    max-height: 880px;

    @include media-breakpoint-up(md) {
      min-height: 480px;
      height: 85vh;
    }

    @at-root .admin-bar & {
      height: calc(100vh - 32px);
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(17, 37, 65, 0.9) 0%, rgba(125, 185, 232, 0) 100%);
  }
}

.c-hero__title {
  position: absolute;
  left: 15px;
  bottom: 30px;

  @include media-breakpoint-up(lg) {
    max-width: 570px;
    bottom: 50px;
  }
  p{
    color: $white-color;
  }
  h1 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 800;
    color: $white-color;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 46px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 42px;
      line-height: 55px;
    }
  }
}
