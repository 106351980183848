.btn {
  font-size: 14px;
  text-transform: uppercase;
  padding-right: 20px;
  padding-left: 20px;
  
}
.caldera-grid input.btn{
	font-weight: 700;
}


#catapult-cookie-bar{
font-size: 13px;
    line-height: 1.8;
 #catapultCookie{
	font-size: 14px;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;

	display: inline-block;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid transparent;
  
    border-radius: 20px;
    transition: all .2s ease-in-out;
    color: #fff;
    background-image: none;
    background-color: transparent !important;
    border-color: #fff;
}
   } 