body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $base-font-family;

  @include media-breakpoint-down(sm) {
    &.has-overlay {
      overflow: hidden;
    }
  }
}

input,
select,
button,
textarea {
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

img {
  max-width: 100%;
  height: auto;
}
